import React from 'react';
import Link from 'gatsby-link';
import { useState } from 'react';

import { slide as Menu } from 'react-burger-menu'

// NAWIGACJA PL
export const Navigation = (props) => {

    const [isMenuOpen, setMenuIsOpen] = useState(false);

    const handleStateChange = (state) => {
        setMenuIsOpen(state.isOpen)
    }

    return (
        <>
            {/* MOBILE MENU  */}
            <Menu right isOpen={isMenuOpen}
                onStateChange={(state) => handleStateChange(state)}
            >
                <Link to="/pl/oferta/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Oferta</Link>
                <Link to="/pl/amber1/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Apartament 1 (Dziwnówek)</Link>
                <Link to="/pl/amber2/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Apartament 2 (Dziwnówek)</Link>
                <Link to="/pl/amber3/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Apartament 3 (Dziwnów)</Link>
                <Link to="/pl/ofertaspecjalna/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Oferty specjalne</Link>
                <Link to="/pl/cennik/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Cennik</Link>
                <Link to="/pl/kontakt/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Kontakt</Link>
            </Menu>

            {/* DESKTOP MENU */}
            <ul className={`nav-items ${props.className}`} style={{ display: 'flex', listStyleType: 'none' }}>
                <li className="nav-item">
                    <Link to="/pl/oferta/" activeClassName="active">Oferta</Link>
                </li>
                <li className="nav-item">
                    <Link activeClassName="active" to="/pl/amber1/">Apartament 1<span>(Dziwnówek)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/pl/amber2/" activeClassName="active">Apartament 2<span>(Dziwnówek)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/pl/amber3/" activeClassName="active">Apartament 3<span>(Dziwnów)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/pl/ofertaspecjalna/" activeClassName="active">Oferty specjalne</Link>
                </li>
                <li className="nav-item">
                    <Link to="/pl/cennik/" activeClassName="active">Cennik</Link>
                </li>
                <li className="nav-item">
                    <Link to="/pl/kontakt/" activeClassName="active">Kontakt</Link>
                </li>
            </ul>
        </>
    )
}


// NAWIGACJA EN
export const NavigationEn = (props) => {

    const [isMenuOpen, setMenuIsOpen] = useState(false);

    const handleStateChange = (state) => {
        setMenuIsOpen(state.isOpen)
    }

    return (
        <>
            {/* MOBILE MENU */}
            <Menu right isOpen={isMenuOpen}
                onStateChange={(state) => handleStateChange(state)}
            >
                <Link to="/en/oferta/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Offer</Link>
                <Link to="/en/amber1/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Apartment 1 (Dziwnówek)</Link>
                <Link to="/en/amber2/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Apartment 2 (Dziwnówek)</Link>
                <Link to="/en/amber3/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Apartment 3 (Dziwnów)</Link>
                <Link to="/en/ofertaspecjalna/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Special offers</Link>
                <Link to="/en/cennik/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Prices</Link>
                <Link to="/en/kontakt/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Contact</Link>
            </Menu>

            {/* DESKTOP MENU */}
            <ul className={`nav-items ${props.className}`} style={{ display: 'flex', listStyleType: 'none' }}>
                <li className="nav-item">
                    <Link to="/en/oferta/" activeClassName="active">Offer</Link>
                </li>
                <li className="nav-item">
                    <Link activeClassName="active" to="/en/amber1/">Apartment 1<span>(Dziwnówek)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/en/amber2/" activeClassName="active">Apartment 2<span>(Dziwnówek)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/en/amber3/" activeClassName="active">Apartment 3<span>(Dziwnów)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/en/ofertaspecjalna/" activeClassName="active">Special offers</Link>
                </li>
                <li className="nav-item">
                    <Link to="/en/cennik/" activeClassName="active">Prices</Link>
                </li>
                <li className="nav-item">
                    <Link to="/en/kontakt/" activeClassName="active">Contact</Link>
                </li>
            </ul>
        </>
    )
}


// NAWIGACJA DE
export const NavigationDe = (props) => {

    const [isMenuOpen, setMenuIsOpen] = useState(false);

    const handleStateChange = (state) => {
        setMenuIsOpen(state.isOpen);
    };

    return (
        <>
            {/* MOBILE MENU */}
            <Menu right isOpen={isMenuOpen}
                onStateChange={(state) => handleStateChange(state)}
            >
                <Link to="/de/oferta/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Angebot</Link>
                <Link to="/de/amber1/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Appartement 1 (Dziwnówek)</Link>
                <Link to="/de/amber2/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Appartement 2 (Dziwnówek)</Link>
                <Link to="/de/amber3/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Appartement 3 (Dziwnów)</Link>
                <Link to="/de/ofertaspecjalna/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Sonderangebote</Link>
                <Link to="/de/cennik/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Preisliste</Link>
                <Link to="/de/kontakt/" className="menu-item" onClick={() => setMenuIsOpen(false)}>Kontakt</Link>
            </Menu>

            {/* DESKTOP MENU */}
            <ul className={`nav-items ${props.className}`} style={{ display: 'flex', listStyleType: 'none' }}>
                <li className="nav-item">
                    <Link to="/de/oferta/" activeClassName="active">Angebot</Link>
                </li>
                <li className="nav-item">
                    <Link activeClassName="active" to="/de/amber1/">Appartement 1<span>(Dziwnówek)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/de/amber2/" activeClassName="active">Appartement 2<span>(Dziwnówek)</span></Link>
                </li>                
                <li className="nav-item">
                    <Link to="/de/amber3/" activeClassName="active">Appartement 3<span>(Dziwnów)</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/de/ofertaspecjalna/" activeClassName="active">Sonderangebote</Link>
                </li>
                <li className="nav-item">
                    <Link to="/de/cennik/" activeClassName="active">Preisliste</Link>
                </li>
                <li className="nav-item">
                    <Link to="/de/kontakt/" activeClassName="active">Kontakt</Link>
                </li>
            </ul>
        </>
    )
}
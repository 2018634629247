import React, { useState, useEffect } from 'react';
import Link from 'gatsby-link';
import SelectLanguage from './SelectLanguage';
import { FormattedMessage } from 'react-intl';
import { Navigation, NavigationEn, NavigationDe } from './Navigation';

const Header = (props) => {
  // const rect = document.body.getBoundingClientRect()
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState({});
  const [scrollY, setScrollY] = useState(bodyOffset.top || 0);
  const [scrollDirection, setScrollDirection] = useState();
  const [selectedLang, setSelectedLang] = useState('pl');

  const handleScroll = (e) => {
    const rect = document.body.getBoundingClientRect()
    setBodyOffset(rect)
    setScrollY(-rect.top)
    setScrollDirection(lastScrollTop > -rect.top ? 'down' : 'up')
    setLastScrollTop(-rect.top)
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let unmounted = false;
    // if (!unmounted){
    //   const rect = document.body.getBoundingClientRect()
    //   setBodyOffset(rect)
    // } 
    window.addEventListener('scroll', handleScroll);

    props.langs.length > 0 && props.langs.map((lang, i) => {
      if (lang.selected == true) {
        setSelectedLang(lang.langKey)
      }
    });

    return () => {
      unmounted = true;
      window.removeEventListener('scroll', handleScroll);
    };
  });


  return (
    <>
      <div className="top-bar"><section>
        <a href="tel:+48 663742379">+48 663 742 379</a>
        <a href="mailto:apartamenty.amber@wp.pl">apartamenty.amber@wp.pl</a></section>
      </div>
      <div className={scrollY > 10 ? 'menu-wrapping color' : 'menu-wrapping'}>
        <div className="menu">
          <Link to={`/${selectedLang}/`}><h1 style={{ fontWeight: 200, fontSize: 20 }}><FormattedMessage id="apartamenty" /> <span className="amber">Amber</span></h1></Link>

          {props.langs.length > 0 && props.langs.map((lang, i) => {
            if (lang.selected == true) {
              if (lang.langKey == 'pl') { return <Navigation /> }
              if (lang.langKey == 'en') { return <NavigationEn /> }
              if (lang.langKey == 'de') { return <NavigationDe /> }
            }
          })}

          {/* {scrollY === 0 && <SelectLanguage langs={props.langs} />} */}
          <SelectLanguage langs={props.langs} />

        </div>
      </div>
    </>
  )
}

export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import Head from './Head';
import Header from '../components/Header';
import Link from 'gatsby-link';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/polyfill-force';

import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { useStaticQuery, graphql } from "gatsby"

import { IntlProvider, FormattedMessage } from "react-intl"


import { FaPhone } from 'react-icons/fa';
import CookieConsent from 'react-cookie-consent';

// styles
import '../styles/default.css';
import '../styles/index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Layout = ({ children, location, messages }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `);

  const url = location.pathname;
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;

  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));
  if (langKey && messages) {
    return (
      <IntlProvider
        locale={langKey}
        messages={messages}
      >
        <div style={{ marginBottom: 100 }}>
          <Head />
          <Header langs={langsMenu} />
          <div
            style={{
              margin: '0 auto',
              paddingTop: 100,
            }}
          >
            {children}
          </div>

          <a className="fixed-phone" href="tel:+48663742379" target="_blank" rel="noreferrer"><FaPhone /></a>

          <CookieConsent
            location="bottom"
            buttonText="Ok, rozumiem"
            disableStyles={true}
            buttonClasses="cookie-btn"
            containerClasses="cookie-bar"
            contentClasses=""
            // declineButtonText="Decline"
            cookieName="gatsby-gdpr-google-analytics">

            Serwis wykorzystuje pliki cookies. Korzystając ze strony wyrażasz zgodę na wykorzystywanie plików cookies.{" "}
            <Link to="/politykaPrywatnosci/"> {" "}Dowiedz się więcej.</Link>
          </CookieConsent>


        </div>
      </IntlProvider>
    )
  }

  return null

};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any.isRequired,
  messages: PropTypes.any.isRequired,
}

export default Layout;

import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';

import mainImage from '../images/photos/AA1.jpg';

const title = 'Apartamenty Amber w Dziwnówku';
const description = 'Komfortowy Apartament Amber z balkonem i miejscem w garażu podziemnym zaprasza Państwa do spędzenia udanego wypoczynku, zaledwie 5 min od plaży w nadmorskiej miejscowości Dziwnówek. Oferuje on w pełni wyposażone mieszkanie dla 4 osób z WiFi i klimatyzacją. ';
const keywords = 'apartamenty, amber, apartament amber, dziwnówek, nocleg, apartamenty nad morzem, apartament blisko plaży, całoroczny, apartament dwupokojowy, klimatyzaja, apartament/y z garażem, mieszkanie nad morzem, mieszkanie dziwnówek,  noclegi nad morzem, noclegi dziwnówek , pokoje dziwnówek, kwatery dziwnówek, wakacje nad morzem,';

const Head = (props) => {

  return (
    <Helmet
      title={title}
      meta={[
        { name: 'title', content: title },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'image', content: mainImage },

        { property: 'og:title', content: title },
        { property: 'og:site_name', content: title },
        { property: 'og:type', content: "website" },
        { property: 'og:url', content: "https://apartamentyamber.com/" },
        { property: 'og:description', content: description },
        { property: 'og:image', content: mainImage },

        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },

      ]}
    />
  )

}

export default injectIntl(Head);
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

const SelectLanguage = (props) => {

  const links = props.langs.map((lang, x) => {
    return (
      <li selected={lang.selected}
      key={x}
      className={(lang.selected ? 'active' : '')}
      style={{
        listStyleType: 'none',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 0,
      }}
    >
      <Link to={lang.link} key={lang.langKey} style={{
        color: 'black',
        textDecoration: 'none',
        marginRight: 10,
      }}>
        {lang.langKey}
      </Link>
    </li>
    )
  }


  );

  return (
    <Fragment>
      <ul style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        right: 0,
        top: 120,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        backgroundColor: '#4abdac'
      }}>
        {links}
      </ul>
    </Fragment>
  );
};

SelectLanguage.propTypes = {
  langs: PropTypes.array
};

export default SelectLanguage;


